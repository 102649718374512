export default {
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    isIE() {
      if (process.browser && this.isMounted) {
        if (!!window.MSInputMethodContext && !!document.documentMode) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    this.isMounted = true
  }
}
